/*
 * @Descripttion: ml 创建
 * @version:
 * @Author: ml
 * @Date: 2019-10-17 11:17:56
 * @LastEditors  : ml
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.use(Vant)

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }

  if (to.query.uname || to.query.t || to.query.uid) {
    let obj = {
      LoginUserId: '',
      LoginUserName: '',
      LoginIp: '',
      LoginCityName: '',
      Token: ''
    }

    if (to.query.uname) {
      obj.LoginUserName = decodeURI(to.query.uname)
    }

    if (to.query.t) {
      obj.Token = decodeURI(to.query.t)
    }

    if (to.query.uid) {
      obj.LoginUserId = decodeURI(to.query.uid)
    }
    store.commit('SetUpBaseInfo', obj)
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
