/*
 * @Descripttion: ml 创建
 * @Author: ml
 * @Date: 2019-10-17 11:19:23
 * @LastEditors: ml
 * @LastEditTime: 2019-12-17 15:23:54
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    LoginUserId: sessionStorage.getItem("_uid") || "",
    LoginUserName: sessionStorage.getItem("_un") || "",
    LoginIp: "",
    LoginCityName: "",
    Token: sessionStorage.getItem("_tok") || "",
  },
  mutations: {
    // 同步
    SetUpBaseInfo(state, obj) {
      if (obj.LoginUserId) {
        state.LoginUserId = obj.LoginUserId
        sessionStorage.setItem("_uid", obj.LoginUserId)
      }

      if (obj.LoginUserName) {
        state.LoginUserName = obj.LoginUserName
        sessionStorage.setItem("_un", obj.LoginUserName)
      }

      if (obj.Token) {
        state.Token = obj.Token
        sessionStorage.setItem("_tok", obj.Token)
      }
    }
  },
  actions: {
    // 异步
  },
  modules: {
  },
})
