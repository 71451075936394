/*
 * @Descripttion: ml 创建
 * @Author: ml
 * @Date: 2019-10-17 11:18:46
 * @LastEditors  : ml
 * @LastEditTime : 2020-08-12 15:19:00
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/cardetail',
    name: 'cardetail',
    meta: {
      title: '车辆详情'
    },
    component: () => import('../views/detectionMange/cardetail.vue')
  },
  {
    path: '/detectiondetail',
    name: 'detectiondetail',
    meta: {
      title: '检测报告',
      desc: '检测详情（不包含车辆基本信息）'
    },
    component: () => import('../views/detectionMange/detectiondetail.vue')
  },
  {
    path: '/detectionreport',
    name: 'detectionreport',
    meta: {
      title: '检测报告',
      desc: '完整检测报告'
    },
    component: () => import('../views/detectionMange/detectionreport.vue')
  },
  {
    path: '/detectionreportpreview',
    name: 'detectionreportpreview',
    meta: {
      title: '检测报告预览'
    },
    component: () =>
      import('../views/detectionMange/detectionReportPreview.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
